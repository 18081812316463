import React, { useEffect, useState, useRef } from 'react';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import './FloatingIsland.css';

const NeutrinoParticle = ({ index, nodes, updatePosition }) => {
  const particleRef = useRef(null);
  const [position, setPosition] = useState({
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight
  });
  const speed = Math.random() * 0.5 + 0.2;
  const angle = Math.random() * Math.PI * 2;
  const size = Math.random() * 3 + 1;

  useEffect(() => {
    let animationFrame;
    const move = () => {
      setPosition(prev => {
        const newX = prev.x + Math.cos(angle) * speed;
        const newY = prev.y + Math.sin(angle) * speed;
        
        const wrappedX = newX < 0 ? window.innerWidth : newX > window.innerWidth ? 0 : newX;
        const wrappedY = newY < 0 ? window.innerHeight : newY > window.innerHeight ? 0 : newY;
        
        updatePosition(index, { x: wrappedX, y: wrappedY });
        return { x: wrappedX, y: wrappedY };
      });
      animationFrame = requestAnimationFrame(move);
    };
    
    move();
    return () => cancelAnimationFrame(animationFrame);
  }, [index, speed, angle, updatePosition]);

  return (
    <motion.div
      ref={particleRef}
      className="neutrino-particle"
      style={{
        width: size,
        height: size,
        x: position.x,
        y: position.y,
        opacity: 0.6,
      }}
      animate={{
        opacity: [0.4, 0.8, 0.4],
        scale: [1, 1.2, 1],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    />
  );
};

const KnowledgeGraph = () => {
  const canvasRef = useRef(null);
  const [nodes, setNodes] = useState([]);
  const PARTICLE_COUNT = 30;
  const CONNECTION_DISTANCE = 150;

  const updateNodePosition = (index, position) => {
    setNodes(prev => {
      const newNodes = [...prev];
      newNodes[index] = position;
      return newNodes;
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrame;

    const drawConnections = () => {
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
      ctx.lineWidth = 0.5;

      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[j].x - nodes[i].x;
          const dy = nodes[j].y - nodes[i].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < CONNECTION_DISTANCE) {
            const opacity = (1 - distance / CONNECTION_DISTANCE) * 0.5;
            ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
            ctx.beginPath();
            ctx.moveTo(nodes[i].x, nodes[i].y);
            ctx.lineTo(nodes[j].x, nodes[j].y);
            ctx.stroke();
          }
        }
      }

      animationFrame = requestAnimationFrame(drawConnections);
    };

    drawConnections();
    return () => cancelAnimationFrame(animationFrame);
  }, [nodes]);

  return (
    <>
      <canvas
        ref={canvasRef}
        className="knowledge-graph"
        width={window.innerWidth}
        height={window.innerHeight}
      />
      {Array.from({ length: PARTICLE_COUNT }).map((_, i) => (
        <NeutrinoParticle
          key={i}
          index={i}
          nodes={nodes}
          updatePosition={updateNodePosition}
        />
      ))}
    </>
  );
};

const FloatingFrame = ({ src }) => {
  const [isHovered, setIsHovered] = useState(false);
  const frameRef = useRef(null);
  
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  
  const springConfig = { damping: 15, stiffness: 150 };
  const xSpring = useSpring(x, springConfig);
  const ySpring = useSpring(y, springConfig);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (frameRef.current) {
        const rect = frameRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        
        const distanceX = (e.clientX - centerX) / 30;
        const distanceY = (e.clientY - centerY) / 30;

        x.set(distanceX);
        y.set(distanceY);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [x, y]);

  return (
    <div className="universe-container">
      <KnowledgeGraph />
      <div className="frame-center-wrapper">
        <motion.div
          ref={frameRef}
          className="floating-frame"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            rotateX: useTransform(ySpring, [-10, 10], [5, -5]),
            rotateY: useTransform(xSpring, [-10, 10], [-5, 5]),
          }}
        >
          <motion.div
            className="content-container"
            animate={{
              scale: isHovered ? 1.02 : 1,
            }}
          >
            <motion.div 
              className="image-wrapper"
              animate={{
                scale: isHovered ? 1.05 : 1,
              }}
            >
              <motion.img
                src={src}
                alt="Profile"
                className="profile-image"
                animate={{
                  filter: isHovered ? 'grayscale(0%) brightness(1.1)' : 'grayscale(100%)',
                }}
                transition={{ duration: 0.4 }}
              />
            </motion.div>

            <motion.div
              className="text-center"
              style={{
                opacity: isHovered ? 1 : 0.95,
                transform: `translateY(${isHovered ? -3 : 0}px)`,
                transition: 'all 0.3s ease'
              }}
            >
              <motion.h1
                className="name"
                style={{
                  textShadow: isHovered 
                    ? '0 0 20px rgba(255, 255, 255, 0.5)'
                    : '0 0 15px rgba(255, 255, 255, 0.3)',
                }}
              >
                Hi, I'm Joneskim
              </motion.h1>
              <motion.div
                className="intro-text"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <p className="title">and welcome</p>
                {/* <p className="subtitle">Exploring the Universe Through Code</p>
                <div className="tags">
                  <span className="tag">Quantum Computing</span>
                  <span className="tag">Neutrino Physics</span>
                  <span className="tag">Web Development</span>
                </div> */}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default FloatingFrame;
