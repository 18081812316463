import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaUser, FaCode, FaLaptopCode, FaEnvelope } from 'react-icons/fa';

const Navbar = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [isHovered, setIsHovered] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const navItems = [
    { id: 'home', icon: FaHome, label: 'Home' },
    { id: 'about', icon: FaUser, label: 'About' },
    { id: 'skills', icon: FaCode, label: 'Skills' },
    { id: 'projects', icon: FaLaptopCode, label: 'Projects' },
    { id: 'contact', icon: FaEnvelope, label: 'Contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = (sectionId) => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <motion.nav 
      className="fixed top-0 w-full flex justify-center z-50"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="mt-4 px-6 py-3 rounded-full backdrop-blur-lg border border-[#3a3a3a] inline-flex"
        animate={{
          backgroundColor: isScrolled ? 'rgba(42, 42, 42, 0.9)' : 'rgba(42, 42, 42, 0.5)',
        }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex items-center justify-center gap-8">
          {navItems.map(({ id, icon: Icon, label }) => (
            <motion.div
              key={id}
              className="relative group"
              onMouseEnter={() => setIsHovered(id)}
              onMouseLeave={() => setIsHovered(null)}
            >
              <motion.button
                className={`p-2 rounded-full transition-colors duration-200 relative ${
                  activeSection === id ? 'text-white' : 'text-gray-400'
                }`}
                onClick={() => handleClick(id)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Icon className="w-5 h-5" />
                
                {/* Active indicator */}
                <AnimatePresence>
                  {activeSection === id && (
                    <motion.div
                      className="absolute inset-0 bg-white/10 rounded-full -z-10"
                      layoutId="activeSection"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: "spring", duration: 0.6 }}
                    />
                  )}
                </AnimatePresence>
              </motion.button>

              {/* Tooltip */}
              <motion.div
                className="absolute left-1/2 -bottom-8 transform -translate-x-1/2 px-2 py-1 bg-[#2a2a2a] text-white text-xs rounded whitespace-nowrap pointer-events-none"
                initial={{ opacity: 0, y: -10 }}
                animate={{ 
                  opacity: isHovered === id ? 1 : 0,
                  y: isHovered === id ? 0 : -10
                }}
                transition={{ duration: 0.2 }}
              >
                {label}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.nav>
  );
};

export default Navbar;
