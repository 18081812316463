import React from 'react';
import { motion } from 'framer-motion';
import { FiGithub, FiExternalLink } from 'react-icons/fi';
import { SiPython, SiReact, SiTensorflow } from 'react-icons/si';
import FloatingFrame from './components/FloatingIsland';
import Navbar from './components/Navbar';
import './App.css';
import profileImage from './assets/IMG_5685.png';

const ProjectCard = ({ title, description, tech, delay, links }) => (
  <motion.div
    className="project-card"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    viewport={{ once: true }}
  >
    <h3 className="project-title">{title}</h3>
    <p className="project-description">{description}</p>
    <div className="project-tech">
      {tech.map((t, i) => (
        <span key={i} className="tech-tag">{t}</span>
      ))}
    </div>
    <div className="project-links">
      {links && (
        <>
          <a href={links.github} target="_blank" rel="noopener noreferrer">
            <button className="icon-button">
              <FiGithub />
            </button>
          </a>
          <a href={links.live} target="_blank" rel="noopener noreferrer">
            <button className="icon-button">
              <FiExternalLink />
            </button>
          </a>
        </>
      )}
    </div>
  </motion.div>
);

const Projects = () => {
  const projects = [
    {
      title: "Wazo AI-Powered Notetaking",
      description: "Modern note-taking app with AI study mode, code execution, and Zettelkasten organization. Features smart content linking, interactive flashcards, and personalized learning paths.",
      tech: ["TypeScript", "React.js", "Python", "Express.js", "LLMs", "Jupyter"],
      links: {
        github: "#",
        live: "#"
      }
    },
    {
      title: "Oasis Learning Management System",
      description: "Custom LMS for African schools with offline functionality, automated quiz grading, and efficient student management. Designed for limited connectivity environments.",
      tech: ["React", "Node.js", "PostgreSQL", "ExpressJS"],
      links: {
        github: "https://github.com/jkim855/Oasis-LMS",
        live: "#"
      }
    },
    {
      title: "Qubit - Quantum Circuit Simulator",
      description: "Interactive simulator for basic quantum circuits. Visualizes quantum gates, basic algorithms, and provides an experimental environment for quantum computing concepts.",
      tech: ["TypeScript", "React.js"],
      links: {
        github: "#",
        live: "https://qubit.joneskimk.space"
      }
    },
  ];

  return (
    <section id="projects" className="projects-section">
      <motion.h2
        className="section-title"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        Featured Projects
      </motion.h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} delay={index * 0.2} />
        ))}
      </div>
    </section>
  );
};

const BackgroundGraphs = () => (
  <div className="knowledge-graphs">
    <div className="knowledge-graph" />
    <div className="knowledge-graph" />
    <div className="knowledge-graph" />
  </div>
);

function App() {
  return (
    <div className="app bg-[#1f1f1f] text-[#eaeaea] min-h-screen">
      <BackgroundGraphs />
      <Navbar />
      
      {/* Home Section */}
      <section id="home" className="min-h-screen flex items-center justify-center">
        <FloatingFrame src={profileImage} />
      </section>

      {/* About Section */}
      <section id="about" className="min-h-screen flex items-center justify-center px-4 py-16">
        <div className="max-w-4xl">
          <h2 className="text-4xl font-bold mb-8 text-center">
            About Me
          </h2>
          <p className="text-lg leading-relaxed">
          I love learning about the universe, building things that help us learn, and turning ideas into reality—all while proudly repping Tanzania!

          </p>
        </div>
      </section>

      {/* Skills Section */}
      <section id="skills" className="min-h-screen flex flex-col items-center justify-center px-4 py-16">
        <div className="max-w-4xl w-full">
          <h2 className="text-4xl font-bold mb-12 text-center">
            Skills
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {['HTML', 'CSS', 'JavaScript', 'React', 'Node.js', 'Express.js', 'Python', 'Django', 'Flask', 'SQL'].map((skill) => (
              <div 
                key={skill} 
                className="bg-[#2a2a2a] p-6 rounded-lg text-center border border-[#3a3a3a] transform hover:scale-105 transition-transform duration-200"
              >
                <span className="text-[#eaeaea] font-courier text-lg">{skill}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Projects />

      {/* Contact Section */}
      <section id="contact" className="min-h-screen flex items-center justify-center px-4 py-16">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 text-[#eaeaea] font-courier tracking-wider">
            Contact
          </h2>
          <div className="space-y-6">
            <a 
              href="mailto:your.email@example.com" 
              className="text-[#eaeaea] hover:text-[#ffffff] font-courier text-xl inline-block transform hover:scale-105 transition-transform duration-200"
            >
              joneskimaminiel@gmail.com
            </a>
            <div className="flex justify-center space-x-8">
              <a 
                href="https://www.linkedin.com/in/joneskim-kimo/" 
                className="text-[#eaeaea] hover:text-[#ffffff] font-courier text-lg transform hover:scale-105 transition-transform duration-200"
              >
                LinkedIn
              </a>
              <a 
                href="https://github.com/joneskim" 
                className="text-[#eaeaea] hover:text-[#ffffff] font-courier text-lg transform hover:scale-105 transition-transform duration-200"
              >
                GitHub
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
